import React from 'react';

class Header extends React.Component {
    render() {
        return (
            <div className='header'>
              <h1>BITCOIN AND CRYPTO</h1>
            </div>
        );
    }
}

export default Header;